<template>
  <div class="popup" @click.self="$emit('close')">
    <div class="popup-content">
      <div class="searchbar-container">
        <input class="searchbar" type="text" v-model="searchTerm" @input="debouncedSearch" placeholder="Search..."
          autofocus autocomplete="one-time-code" autocorrect="off" spellcheck="false">
      </div>
      <div class="results-container" v-if="filteredCountries.length > 0">
        <div class="result-item" v-for="result in filteredCountries" :key="result.name">
          <span :class="{ 'guessed-country': guessedCountries.includes(result.name) }">{{ result.name }}</span>
          <button v-if="!guessedCountries.includes(result.name)" @click="handleGuess(result)" class="guess-button">
            Guess
          </button>
          <button v-else class="guess-button guessed" disabled>
            Guessed
          </button>
        </div>
      </div>
      <transition name="fade">
        <p v-if="showNoResultsMessage" class="no-results-message">No results found</p>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import sanitizeString from '@/utils/sanitizeString';

export default {
  name: 'SearchComponent',
  props: {
    guessedCountries: {
      type: Array,
      default: () => []
    },
    countries: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      searchTerm: '',
      filteredCountries: [],
      showNoResults: false,
      searchTimeout: null
    };
  },
  computed: {
    showNoResultsMessage() {
      return this.showNoResults && this.searchTerm && this.filteredCountries.length === 0;
    }
  },
  methods: {
    debouncedSearch: _.debounce(function() {
      this.search();
    }, 300), // <-- The issue is here, remove the closing parenthesis
               //     because _.debounce takes only one argument, a function.
               //     The closing parenthesis at the end of the line should be removed.
               //     The corrected line should be: }, 300),
    search() {
      if (this.searchTerm.length < 3) {
        this.filteredCountries = [];
        this.showNoResults = false;
        return;
      }

      const sanitizedSearchTerm = sanitizeString(this.searchTerm);
      this.filteredCountries = this.countries.filter(country =>
        sanitizeString(country.name).includes(sanitizedSearchTerm)
      ).slice(0, 5); // Limit to 5 results

      this.showNoResults = this.filteredCountries.length === 0;
    },
    handleGuess(guess) {
      this.$emit('guess', guess);
    }
  },
  mounted() {
    this.$el.querySelector('.searchbar').focus();
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  z-index: 70;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 500px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: auto;
  margin-bottom: 350px;
  font-family: Arial;
}

.searchbar-container {
  border-radius: 10px;
}

.searchbar {
  width: 100%;
  max-width: calc(100% - 40px);
  height: 50px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  padding: 0 20px;
  outline: none;
  transition: box-shadow 0.3s ease;
}

.results-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding-top: 10px;
  margin-top: -15px;
}

.result-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  height: 30px; /* Set a fixed height */
  word-wrap: break-word; /* Allow long names to break into new lines */
  text-align: left;
}

.result-item:hover {
  background-color: #f9f8f8;
  border-radius: 10px;
}

.result-item:last-child {
  border-bottom: none;
}

.guessed-country {
  color: #999999;
  /* Gray out the text for guessed countries */
}

.guess-button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: rgb(53, 53, 72);
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.guess-button:hover {
  background-color: rgb(84, 84, 154);
}

.guess-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.guess-button.guessed {
  background-color: #ccc;
  color: #999999;
}
.no-results-message {
  position: absolute;
  top: 30px; /* Adjust the top value to move the message right under the search bar */
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  margin-top: 5px; /* Maintain the margin-top value for spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: -10;
  height: 20px;
  color: #999999;
}

@media screen and (max-width: 768px) {
  .popup-content {
    max-width: calc(100% - 100px);
    font-size: 14px;
  }
}
</style>