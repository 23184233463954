export const CDN_BASE_URL = 'https://teuteuf-dashboard-assets.pages.dev/';
export const CDN_DATA_URL = `${CDN_BASE_URL}data/`;
const _cache = {};

async function fetchJSONFromCDN(endpoint, useCache = true) {
  if (useCache && _cache[endpoint]) {
    return _cache[endpoint];
  }

  // Fetch data, and cache it
  const data = await fetch(CDN_DATA_URL + endpoint)
    .then((res) => res.json())
    .catch(() => null);
  _cache[endpoint] = data;
  return data;
}

export function getCountryFlagSvgUrl(countryCode) {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/flags/gb.svg
  return `${CDN_DATA_URL}common/flags/${countryCode.toLowerCase()}.svg`;
}

export async function getCountries() {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/countries.json
  const data = await fetchJSONFromCDN(`common/countries.json`);
  return data;
}

export async function getCountryData(countryCode) {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/common/countries/gb.json
  const data = await fetchJSONFromCDN(
    `common/countries/${countryCode.toLowerCase()}.json`
  );
  return data;
}

export async function getCountryGeoGridData(countryCode) {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/geogrid/countries/gb.json
  const data = await fetchJSONFromCDN(
    `geogrid/countries/${countryCode.toLowerCase()}.json`
  );
  return data;
}

export async function getBoard(boardId) {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/geogrid/boards/1.json
  const data = await fetchJSONFromCDN(`geogrid/boards/${boardId}.json`);
  return data;
}

export async function getVersion() {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/version.json
  const data = await fetchJSONFromCDN(`version.json`, false);
  return data;
}

export async function getGameInfo() {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/teuteuf-games/games-info.json
  const data = await fetchJSONFromCDN('teuteuf-games/games-info.json');
  return data;
}

export function getGameIconUrl(gameName) {
  // Example: https://teuteuf-dashboard-assets.pages.dev/data/teuteuf-games/icons/worldle.svg
  return `${CDN_DATA_URL}teuteuf-games/icons/${gameName
    .split(' ')
    .join('_')
    .toLowerCase()}.svg`;
}