export const NO_DATA_CATEGORIES = [
  'hdi_over_x',
  'hdi_under_x',
  'gdp_per_capita_over_x',
  'gdp_per_capita_under_x',
  'cpi_over_x',
  'cpi_under_x',
  'air_pollution_over_x',
  'air_pollution_under_x',
  'co2_emissions_per_capita_over_x',
  'co2_emissions_per_capita_under_x',
];

export const checkGeoGridDataForNoData = (categoryId, data) => {
  switch (categoryId) {
    case 'hdi_over_x':
    case 'hdi_under_x':
      return (
        data.economicInfo.HDI === null || data.economicInfo.HDI === undefined
      );
    case 'gdp_per_capita_over_x':
    case 'gdp_per_capita_under_x':
      return (
        data.economicInfo.GDPPerCapita === null ||
        data.economicInfo.GDPPerCapita === undefined
      );
    case 'cpi_over_x':
    case 'cpi_under_x':
      return (
        data.politicalInfo.CPI === null || data.politicalInfo.CPI === undefined
      );
    case 'air_pollution_over_x':
    case 'air_pollution_under_x':
      return (
        data.factsInfo.airPollution === null ||
        data.factsInfo.airPollution === undefined
      );
    case 'co2_emissions_per_capita_over_x':
    case 'co2_emissions_per_capita_under_x':
      return (
        data.factsInfo.co2Emissions === null ||
        data.factsInfo.co2Emissions === undefined
      );
  }
  return false;
};
