<template>
    <div class="toast-container animate-reveal" role="alert">
    <div class="toast-content">
      <div class="toast-body">
        <div class="toast-message">
          <span style="font-weight: bold;">{{  countryName }}</span>
          <span>&nbsp;doesn't have any data for the category&nbsp;</span>
          <span style="font-weight: bold;">{{  category }}</span>
          <span>,&nbsp;so it can't be a valid answer.&nbsp;</span>
        </div>
        <div>
          <button @click.self="$emit('close')" class="toast-button toast-primary-button">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countryName: String,
    category: String,
  }
};
</script>

<style scoped>
.toast-container {
  width: 100%;
  z-index: 99991;
  position: fixed;
  top: 16px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  pointer-events: none;
}

.toast-content {
  display: flex;
  background-color: rgb(73, 73, 90);
  border-radius: 8px;
  padding: 16px;
  pointer-events: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.toast-body {
  margin-left: 12px;
}

.toast-message {
  margin-bottom: 10px;
  font-size: 14px;
  color: rgb(223, 223, 223);
  max-width: 300px;
}

.toast-button {
  display: inline-flex;
  justify-content: center;
  width: 30%;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.toast-primary-button {
  color: rgb(223, 223, 223);
  background-color: #2563eb;
  border: none;
}

.toast-primary-button:hover {
  background-color: #1d4ed8;
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-reveal {
  animation: reveal 0.3s ease forwards;
}
</style>